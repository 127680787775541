import { render, staticRenderFns } from "./a-teacher-competition-29-03-2022-modal.vue?vue&type=template&id=684eb7f2&scoped=true&"
import script from "./a-teacher-competition-29-03-2022-modal.vue?vue&type=script&lang=js&"
export * from "./a-teacher-competition-29-03-2022-modal.vue?vue&type=script&lang=js&"
import style0 from "./a-teacher-competition-29-03-2022-modal.vue?vue&type=style&index=0&id=684eb7f2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "684eb7f2",
  null
  
)

export default component.exports