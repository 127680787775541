<template>
	<b-modal ref="modal-launch-competition" hide-title hide-footer size='lg'>
		<template #modal-header="{ close }">
			<svg-cross-icon @click="close()"/>
		</template>
		<img
			class="w-100 mb-3"
			src="./assets/teacher-competition-29-03-2022-modal.png"
			alt="Foto - tím Zmudri"
		>
		<div class="ml-5 mr-5 mb-3 d-flex justify-content-center align-items-center">
			<a href="https://drive.google.com/file/d/1Ka2J30IWcFyR6FI4tWtX2yIYDpzUVZMk/view?usp=sharing" target="_blank">
				<b-button variant="primary" class="a-poppins-btn -wider mt-3">{{ $t('Chcem súťažiť') }}</b-button>
			</a>
		</div>
	</b-modal>
</template>

<script>
export default {
	components: {
		'svg-cross-icon': () => import('@/plugins/appzmudri/_theme/icon/cross.svg?inline'),
	},

	methods: {
		showModal(e) {
			this.group = e
			this.$nextTick(() => {
				this.$refs['modal-launch-competition'].show()
			})
		},
	}
}
</script>

<style lang="scss" scoped>
	svg {
		cursor: pointer;
	}

	h4 {
		font-family: Boing !important;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		line-height: 29px;
		color: #2B2E4A;
	}

	::v-deep .modal-header {
		border-bottom: 0 none;
	}

	::v-deep .modal-content {
		top: 150px;
	}

	::v-deep .modal-body {
		p {
			font-family: Roboto;
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 21px;
			margin-top: auto !important;
			margin-bottom: auto !important;
			align-items: center;

			color: #000000;
		}
		.modal-box {
			align-items: center;
			width: 569px;
			height: 94px;
			left: 617px;
			top: 847px;

			background: #FFF5D1;
			border: 1px solid #E6D8A7;
			box-sizing: border-box;
			border-radius: 4px;
		}
		.modal-box-text {
			font-family: Poppins;
			font-style: normal;
			font-weight: bold;
			font-size: 14px;
			line-height: 18px;

			letter-spacing: -0.05em;

			color: #CAB979;
		}
	}
	input {
		border: none;
		border-bottom: 1px solid #CADEEA;
		width: 100%;
		&::placeholder {
			color: rgba(156, 161, 174, 0.4) !important;
			font-family: Poppins;
			font-style: normal;
			font-weight: bold;
			font-size: 18px;
			line-height: 27px;
		}
		&:focus {
			outline: 0;
		}
		&:read-only {
			background-color: transparent;
		}
	}


	::v-deep .modal-content {
		border-style: solid;
		border-width: 1px;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		border-image: linear-gradient(90.01deg, #0064FF 60.75%, #FB2564 79.89%) 0 0 100% 0/0 0 4.53px 0 stretch;
	}

	::v-deep .modal-footer {
		border-top: 0 none !important;
		align-items: left;
	}

	.modal-btn {
		width: 221px;
		height: 54px;
		left: 782px;
		top: 767px;
	}

	.box-content {
		margin: auto;
		padding: 20px;
		justify-content: center;
	}

	.box-bottom {
		width: 100%;
		background: #FFF5D1;
		border: 1px solid #E6D8A7;
		box-sizing: border-box;
		align-items: center ;
		border-radius: 4px;
	}
</style>